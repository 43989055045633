"use client";
import React from "react";
import { FaShoppingCart } from "react-icons/fa";
function Cart() {
	return (
		<div className='text-white px-2 rounded-xl hover:border-2 border-white w-10 h-10 flex justify-center items-center transition-all ease-in-out duration-100  '>
			<a href='/cart'>
				{" "}
				<FaShoppingCart
					size={24}
					color='#fff'
				/>
			</a>
		</div>
	);
}

export default Cart;
