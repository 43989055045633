import React from "react";
import { useRef, useEffect } from "react";
import { motion, useAnimation, useInView } from "framer-motion";

type ArabicSizesProps = {
	enLanguage: boolean;
};
const ArabicSizes: React.FC<ArabicSizesProps> = ({ enLanguage }) => {
	const controls = useAnimation();
	const sizes = [
		{
			id: 1,
			size: "للقمصان المصنوعة من القماش التخين مع فتحة الذراع مخيطة عند الكتف ~ مقاس كبير.",
			a: "https://buy.stripe.com/bIYdSLgvh6tV8Ao14a",
		},
		{
			id: 2,
			size: "للقمصان المصنوعة من القماش التخين مع فتحة الذراع مخيطة عند الكتف ~ مقاس صغير.",
			a: "https://buy.stripe.com/dR6dSLa6T05xdUIfZ3",
		},
		{
			id: 3,
			size: "للقمصان المصنوعة من القماش الغير تخين مع فتحة الذراع مخيطة تحت الكتف ~ مقاس كبير.",
			a: "https://buy.stripe.com/6oE01Vfrd6tVcQE4gk",
		},
		{
			id: 4,
			size: "للقمصان المصنوعة من القماش الغير تخين مع فتحة الذراع مخيطة تحت الكتف ~ مقاس صغير.",
			a: "https://buy.stripe.com/cN2bKD5QD4lNg2QbIL",
		},
	];

	const pay = () => {
		{
			/* <script src="https://js.stripe.com/v3"></script>

<!-- Create a button that your customers click to complete their purchase. Customize the styling to suit your branding. -->
<button
  style="background-color:#6772E5;color:#FFF;padding:8px 12px;border:0;border-radius:4px;font-size:1em;cursor:pointer"
  id="checkout-button-price_1PQamS058xmatu2c0LLKUg4w"
  role="a "
  type="button"
>
  Checkout
</button>

<div id="error-message"></div> */
		}
	};
	const ref = useRef(null);
	const inView = useInView(ref, { once: true });

	useEffect(() => {
		if (inView) {
			controls.start("visible");
		}
	}, [inView, controls]);
	return (
		<div>
			<h2 className='text-5xl font-bold text-primary text-center mt-40 mb-10'>
				المقاسات{" "}
			</h2>
			<div
				className='grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4 my-10'
				dir={enLanguage ? "ltr" : "rtl"}
			>
				{sizes.map((size, index) => {
					return (
						<motion.div
							key={size.id}
							ref={ref}
							className='size border-2 border-primary cursor-pointer py-10 px-5 rounded-lg shadow-md transition-all ease-in-out duration-300 shadow-primary text-primary hover:text-white hover:bg-primary'
							initial='hidden'
							animate={controls}
							variants={{
								hidden: { opacity: 0, scale: 0.8 },
								visible: { opacity: 1, scale: 1 },
							}}
							transition={{
								delay: index * 0.1,
								duration: 0.5,
								type: "spring",
								stiffness: 50,
							}}
							whileHover={{ scale: 1.1 }}
						>
							<div className='flex flex-col items-center justify-between gap-5'>
								<h3 className='text-3xl text-center '>{size.size}</h3>

								<a
									href={size.a}
									target='_blank'
									className='text-3xl text-center py-3 px-6 border-2 border-primary bg-primary text-white rounded-lg hover:bg-white hover:text-primary transition-all ease-in-out duration-300'
								>
									اشتري الان
								</a>
							</div>
						</motion.div>
					);
				})}
			</div>
		</div>
	);
};

export default ArabicSizes;
