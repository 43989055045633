import React from "react";
import { useState, useEffect } from "react";
import "../App.css";
import { useDispatch, useSelector } from "react-redux";
import Header from "../components/Header.tsx";
import { toggleLanguage, setLanguage } from "../redux/slices/langSlice";
import Footer from "../components/Footer.tsx";
import EnglishText from "../components/EnglishText.tsx";
import ArabicText from "../components/ArabicText.tsx";
import HoveredBtn from "../components/HoveredBtn.tsx";
import ArabicSizes from "../components/ArabicSizes.tsx";
import ElegantImgs from "../components/ElegantImgs.tsx";
import EnglishSizes from "../components/EnglishSizes.tsx";
import HeroSection from "../components/HeroSection.tsx";
import VideoSection from "../components/VideoSection.tsx";
function Home(props) {
	const [enLangueage, setEnLangueage] = useState(true);
	const dispatch = useDispatch();

	// Accessing the Redux state
	const enLanguage = useSelector((state) => state.lang.enLanguage); // Make sure 'lang' matches the reducer name in your store setup

	// Local state (if needed)
	const [localLanguage, setLocalLanguage] = useState(enLanguage);

	// Logging the Redux state to ensure it's being accessed correctly
	console.log("Redux enLanguage:", enLanguage);
	const handleLanguageToggle = () => {
		dispatch(toggleLanguage());
	};
	useEffect(() => {
		// Retrieve the value from sessionStorage
		const storedLang = sessionStorage.getItem("enLanguage");

		// Parse the value to a boolean
		const isEnLanguage = storedLang ? JSON.parse(storedLang) : true;

		console.log("storedLang", storedLang);

		// Dispatch the action if needed
		if (isEnLanguage) {
			dispatch(toggleLanguage());
		}
	}, [dispatch]);

	return (
		<>
			<Header enLanguage={enLangueage} setEnLanguage={setEnLangueage} />
			<div className='Home mx-5 md:m-x20 lg:mx-40'>
				{enLangueage ? (
					<EnglishText enLanguage={enLangueage} />
				) : (
					<ArabicText enLanguage={enLangueage} />
				)}
				<HeroSection enLanguage={enLangueage} />
				<ElegantImgs enLanguage={enLangueage} />
				{enLangueage ? (
					<EnglishSizes />
				) : (
					<ArabicSizes enLanguage={enLangueage} />
				)}
				<VideoSection />
				<HoveredBtn enLanguage={enLangueage} />
			</div>
			<Footer enLanguage={enLangueage} />
		</>
	);
}

export default Home;
