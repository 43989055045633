"use client";
import React from "react";

type FooterProps = {
	enLanguage: boolean;
};
const Footer: React.FC<FooterProps> = ({ enLanguage }) => {
	return (
		<div className='mt-10 py-20 px-5 md:px-20 lg:px-40 gap-5	flex flex-col md:flex-row justify-between items-center flex-wrap bg-primary'>
			<img
				src={enLanguage ? "/en.webp" : "/ar.webp"}
				alt='logo'
				width={140}
				height={140}
				loading='lazy'
			/>
			<h3 className='text-white text-3xl text-center'>
				{!enLanguage
					? "ارتقي بأستايلك مع شولديز"
					: "Elevate your style with Shouldees"}
			</h3>
			<div className='flex  justify-center flex-row-reverse flex-wrap items-center gap-5 text-white'>
				<a
					className=' underline py-3'
					href={"/privacy-policy"}
				>
					{enLanguage ? "Privacy Policy" : "سياسة الخصوصية"}
				</a>
				<a
					className=' underline py-3'
					href={"/terms"}
				>
					{enLanguage ? "Terms of Service" : "شروط الخدمة"}
				</a>
				<a
					className=' underline py-3'
					href={"/returns"}
				>
					{enLanguage ? "Returns & Exchanges" : "سياسة الإرجاع والاستبدال"}
				</a>
				<a href={`mailto:shouldees@shouldees.com?subject=Main Page`}>
					shouldees@shouldees.com
				</a>
			</div>
		</div>
	);
};

export default Footer;
