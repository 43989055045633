import React from "react";
import { motion } from "framer-motion";

type ElegantProps = {
	enLanguage: boolean;
};

const ElegantImgs: React.FC<ElegantProps> = ({ enLanguage }) => {
	const images = [
		{
			id: 3,
			srcImg: "/images/5edited.webp",
		},
		{
			id: 4,
			srcImg: "/images/14.webp",
		},
		{
			id: 5,
			srcImg: "/images/11.webp",
		},
		{
			id: 6,
			srcImg: "/images/21-1-1.webp",
		},
		{
			id: 7,
			srcImg: "/images/30.webp",
		},
		{
			id: 8,
			srcImg: "/images/42.webp",
		},
		{
			id: 9,
			srcImg: "/images/WechatIMG535.webp",
		},
		{
			id: 10,
			srcImg: "/images/WechatIMG519.webp",
		},
	];
	return (
		<div className='my-20 grid grid-cols-2 md:grid-cols-3 gap-4'>
			{images.map((img, index) => (
				<motion.div
					key={img.id}
					className='relative h-[300px] md:h-[700px] shadow-2xl shadow-black/50 transition-all ease-in-out duration-300 items-center object-cover max-w-full rounded-lg'
					initial={{ opacity: 0, x: -100 }}
					animate={{ opacity: 1, x: 0 }}
					whileInView={{ y: [10, 0], x: [-5, 0], opacity: [0, 1] }}
					transition={{
						delay: index * 0.1,
						duration: 0.5,
						type: "spring",
						stiffness: 50,
					}}
				>
					<div>
						<img
							className='h-[300px] md:h-[700px] shadow-2xl shadow-black/50 hover:object-center hover:scale-105 transition-all ease-in-out duration-300  items-center object-cover  max-w-full rounded-lg'
							src={img.srcImg}
							alt='image'
							width={500}
							height={500}
							loading='lazy'
						/>
					</div>
				</motion.div>
			))}
		</div>
	);
};

export default ElegantImgs;
