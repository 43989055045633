import React from "react";

function ReturnsInArabic() {
	return (
		<div
			dir='rtl'
			className='px-5 md:px-20 lg:px-40 flex flex-col gap-5 mt-40'
		>
			<h2 className='text-3xl font-bold'>سياسة الإرجاع والاستبدال</h2>

			<div className='py-1'>
				في Shouldees، نريدك أن تكون راضيًا تمامًا عن مشترياتك. إذا كنت غير راضٍ
				عن طلبك، فإننا نقدم لك عملية إرجاع بسيطة وواضحة. يُرجى التأكد من قراءة
				سياسة الإرجاع الخاصة بنا بعناية قبل بدء عملية الإرجاع.
			</div>
			<div className='py-1'>
				<h3 className='font-bold text-lg'>شروط الإرجاع</h3>
				<span className='font-bold pr-2'>الأهلية:</span> الأهلية: يجب أن يكون
				المنتج المُراد إرجاعه غير مستخدم، وغير مغسول، وفي نفس الحالة التي
				استلمته بها، بما في ذلك العبوة الأصلية. الإطار الزمني: لديك 30 يومًا من
				تاريخ الشراء لبدء عملية الإرجاع. المنتجات غير القابلة للإرجاع: لا يمكنك
				إرجاع المنتجات التي تم ارتداؤها، أو غسلها، أو التي ليست في عبوتها
				الأصلية.
			</div>
			<div className='py-1'>
				<h3 className='font-bold text-lg'>عملية الإرجاع</h3>
				<span className='font-bold pr-2'>الاتصال بنا:</span> قبل إرجاع أي منتج،
				يُرجى التواصل مع فريق خدمة العملاء لدينا على{" "}
				<a href={`mailto:shouldees@shouldees.com?subject=Returns`}>
					shouldees@shouldees.com
				</a>
				مع ذكر رقم الطلب وتفاصيل المنتج الذي ترغب في إرجاعه.
			</div>
			<div className='py-1'>
				<h3 className='font-bold text-lg'>تكاليف الشحن للإرجاع:</h3>
				يتحمل العملاء تكلفة شحن الإرجاع. يُرجى استخدام خدمة شحن قابلة للتتبع أو
				شراء تأمين للشحن. لا يمكننا ضمان استلام المنتج المرتجع. الاسترداد: بمجرد
				استلام الإرجاع وفحصه، سنرسل لك بريدًا إلكترونيًا لإعلامك بالموافقة على
				الاسترداد أو رفضه. إذا تمت الموافقة، سيتم معالجة استردادك، وسيتم
				تلقائيًا تطبيق رصيد على طريقة الدفع الأصلية الخاصة بك في غضون عدد معين
				من الأيام.
			</div>
			<div className='py-1'>
				<h3 className='font-bold text-lg'>ملاحظات هامة</h3>
				نحن لا نتحمل تكلفة شحن الإرجاع. لن يتم قبول المنتجات التي يتم إرجاعها
				دون التواصل مع خدمة العملاء أو التي لا تفي بمعايير الإرجاع.
			</div>
			<div className='py-1'>
				<h3 className='font-bold text-lg'>هل تحتاج إلى مساعدة؟</h3>
				إذا كان لديك أي أسئلة حول سياسة الإرجاع الخاصة بنا، يُرجى التواصل معنا
				عبر البريد الإلكتروني على{" "}
				<a href={`mailto:shouldees@shouldees.com?subject=Returns`}>
					shouldees@shouldees.com
				</a>
			</div>
		</div>
	);
}

export default ReturnsInArabic;
