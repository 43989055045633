import Footer from "../components/Footer.tsx";
import Header from "../components/Header.tsx";
import { useDispatch, useSelector } from "react-redux";
import { toggleLanguage, setLanguage } from "../redux/slices/langSlice";

import HoveredBtn from "../components/HoveredBtn.tsx";
import TermsEnglish from "../components/TermsEnglish.tsx";
import TermsArabic from "../components/TermsArabic.tsx";
function Terms() {
	const dispatch = useDispatch();

	const enLanguage = useSelector((state) => state?.lang?.enLanguage);

	console.log("Redux enLanguage:", enLanguage);
	const handleLanguageToggle = () => {
		// dispatch(toggleLanguage());
	};

	return (
		<>
			<Header enLanguage={enLanguage} setEnLanguage={handleLanguageToggle} />
			{enLanguage ? <TermsEnglish /> : <TermsArabic />}
			<HoveredBtn enLanguage={enLanguage} />
			<Footer enLanguage={enLanguage} />
		</>
	);
}

export default Terms;
