import React from 'react'

function PrivacyPolicyInArabic() {
  return (
	<>
			<div
				className='px-5 md:px-20 lg:px-40 flex flex-col gap-5 mt-40'
				dir='rtl'
			>
				<h2 className='text-3xl font-bold'>سياسة الخصوصية لشركة شولديز</h2>
				<ul className='flex flex-col gap-5'>
					<li className=' font-semibold text-xl'>
						خصوصيتك مهمة لنا في شولديز. إليك كيفية تعاملنا مع معلوماتك:
					</li>
					<li>
						<span className='font-bold pl-2'>جمع المعلومات:</span> نقوم بجمع
						اسمك، بريدك الإلكتروني، وتفاصيل الدفع عندما تقوم بعملية شراء أو
						تشترك في نشرتنا الإخبارية.
					</li>
					<li>
						<span className='font-bold pl-2'>استخدام المعلومات:</span> نستخدم
						بياناتك لمعالجة الطلبات، والتواصل معك، وتحسين خدماتنا. قد نستخدمها
						لأغراض تسويقية بموافقتك.
					</li>
					<li>
						<span className='font-bold pl-2'>حماية البيانات:</span> نؤمن بياناتك
						من الوصول غير المصرح به. يتم معالجة المدفوعات من خلال بوابات موثوقة.
					</li>
					<li>
						<span className='font-bold pl-2'>مشاركة الأطراف الثلاثة:</span> نحن
						لا نبيع أو نشارك بياناتك إلا لتقديم الخدمات الضرورية (مثل الشحن) أو
						للامتثال للمتطلبات القانونية.
					</li>
					<li>
						<span className='font-bold pl-2'>
							ملفات تعريف الارتباط (الكوكيز)
						</span>
						: نستخدم الكوكيز لتحسين تجربتك وجمع التحليلات. يمكنك تعطيل الكوكيز
						من إعدادات المتصفح.
					</li>
					<li>
						<span className='font-bold pl-2'>حقوقك:</span> يمكنك الوصول إلى
						معلوماتك الشخصية أو تحديثها أو حذفها عن طريق التواصل معنا.
					</li>
				</ul>
				<h3>
					<span className='font-bold'>تواصل معنا:</span> لأي استفسارات، راسلنا
					على .
					<a
						href={`mailto:shouldees@shouldees.com?subject=Privacy Policy Inquiry`}
					>
						shouldees@shouldees.com
					</a>{" "}
					.
				</h3>
				<h3>باستخدام خدماتنا، فإنك توافق على سياسة الخصوصية هذه.</h3>
			</div>
		</>
  )
}

export default PrivacyPolicyInArabic