import React from "react";
import Card from "./Card.tsx";

type EnglishTextProps = {
	enLanguage: boolean;
};
const EnglishText: React.FC<EnglishTextProps> = ({ enLanguage }) => {
	return (
		<div className='flex justify-center flex-col md:flex-row gap-10 mt-40'>
			<h3 className='text-primary text-[22px] md:text-3xl mt-0 md:mt-28 leading-[1.5]'>
				Elevate your style with Shouldees Anti-Slip Shoulder Pads: reusable pads
				enhance your shoulder shape for a sharp, polished look with 90-degree
				shoulder lines. The silicone part provides stability and prevents bra
				straps from digging into your skin, adding effortless
				elegance to any outfit.
			</h3>
			<Card enLanguage={enLanguage} />
		</div>
	);
};

export default EnglishText;
