import React from "react";

type HeroProps = {
	enLanguage: boolean;
};
const Card: React.FC<HeroProps> = ({ enLanguage }) => {
	return (
		<div className='card-section  flex justify-between flex-col md:flex-row gap-5 items-center'>
			<div className='e-card playing overflow-hidden'>
				<div className='image'></div>

				<div className='wave'></div>
				<div className='wave'></div>
				<div className='wave'></div>

				<div className='infotop'>
					<img
						src='/favicon.png'
						alt='faviocn'
						width={80}
						height={80}
						loading='lazy'
					/>
					{enLanguage ? "Shouldees" : "شولديز"}
					<div className='name text-3xl text-white px-3'>
						{enLanguage
							? "Elevate your style with Shouldees"
							: " وسادات الكتف المانعة للإنزلاق التي تمنحك مظهراً مصقولاً وانيقاً"}
					</div>
				</div>
			</div>
		</div>
	);
};

export default Card;
