import React from 'react'

function TermsEnglish() {
  return (
    <>
    <div className='px-5 md:px-20 lg:px-40 flex flex-col gap-5 mt-40'>
        <h2 className='text-3xl font-bold'>Terms of Service for Shouldees</h2>
        <ul className='flex flex-col gap-5'>
            <li>
                <span className='font-bold pr-2'> 1. Acceptance of Terms:</span> By
                using the Shouldees website, you agree to these Terms of Service. If
                you do not agree, do not use our services.
            </li>
            <li>
                <span className='font-bold pr-2'> 2. Use of the Website:</span> Use
                the Shouldees website for lawful purposes only. Do not post harmful
                or offensive material.
            </li>
            <li>
                <span className='font-bold pr-2'>
                    {" "}
                    3. Account Responsibilities:{" "}
                </span>{" "}
                Provide accurate information when creating an account. You are
                responsible for your account and password.
            </li>
            <li>
                <span className='font-bold pr-2'> 4. Product Purchases:</span>{" "}
                Orders are subject to acceptance. We reserve the right to refuse or
                cancel any order. Prices and availability may change without notice.
            </li>
            <li>
                <span className='font-bold pr-2'> 5. Shipping and Delivery:</span>{" "}
                Delivery times may vary. We are not responsible for shipping delays
                or issues.
            </li>
            <li>
                <span className='font-bold pr-2'> 6. Returns and Refunds: </span>{" "}
                Returns and refunds are accepted per our Return Policy. Products
                must be returned in original condition.
            </li>
            <li>
                <span className='font-bold pr-2'> 7. Intellectual Property: </span>{" "}
                Content on the Shouldees website is protected by intellectual
                property laws. Do not use content without permission.
            </li>
            <li>
                <span className='font-bold pr-2'> 8. Limitation of Liability:</span>{" "}
                Shouldees is not liable for any damages arising from the use of our
                website or products.
            </li>
            <li>
                <span className='font-bold pr-2'> 9. Disclaimer:</span> Shouldees
                Anti-Slip Shoulder Pads are made from safe, high-quality materials
                with no known issues. However, the use of this product is at the
                user’s discretion. Shouldees assume no responsibility or liability
                for any unforeseen reactions or misuse. Discontinue use if any
                discomfort occurs.
            </li>
            <li>
                <span className='font-bold pr-2'> 10. Indemnification: </span>You
                agree to indemnify Shouldees from any claims or damages related to
                your use of our website or violation of these terms.
            </li>
            <li>
                <span className='font-bold pr-2'> 11. Changes to Terms: </span> We
                may update these Terms of Service. Continued use of the website
                means you accept the updated terms.
            </li>
            <li>
                <span className='font-bold pr-2'> 12. Governing Law: </span> These
                terms are governed by the laws of [Your Country/State]. Disputes
                will be resolved in [Your Country/State] courts.
            </li>
            <li>
                <span className='font-bold pr-2'>Contact Us:</span> For questions,
                contact us at{" "}
                <a href={`mailto:shouldees@shouldees.com?subject=Terms of Service`}>
                    shouldees@shouldees.com
                </a>
                <br />
                By using our services, you agree to these Terms of Service
            </li>
            .
        </ul>
    </div>
</>
  )
}

export default TermsEnglish