import React from "react";
import PrivacyPolicyPage from "../components/PrivacyPolicyPage.tsx";

function PrivacyPolicy() {
	return (
		<div>
			<PrivacyPolicyPage />
		</div>
	);
}

export default PrivacyPolicy;
