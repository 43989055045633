"use client";
import { useEffect, useState } from "react";
import React from "react";
import Navbar from "./Navbar";
import { toggleLanguage, setLanguage } from "../redux/slices/langSlice";
import { useDispatch, useSelector } from "react-redux";
import Cart from "./Cart.tsx";

type HeaderProps = {
	enLanguage: boolean;
	setEnLanguage: (value: boolean) => void;
};

const Header: React.FC<HeaderProps> = ({ enLanguage, setEnLanguage }) => {
	const [isNavbarOpen, setIsNavbarOpen] = useState(false);
	const dispatch = useDispatch();
	const updateSessionStorage = (value: boolean) => {
		sessionStorage.setItem("enLanguage", JSON.stringify(value));
	};

	const handleLanguageToggle = (value: boolean) => {
		setEnLanguage(value);
		updateSessionStorage(value);
		dispatch(toggleLanguage());
	};

	useEffect(() => {
		const storedLanguage = sessionStorage.getItem("enLanguage");
		if (storedLanguage !== null) {
			setEnLanguage(JSON.parse(storedLanguage));
		}
	}, [setEnLanguage]);

	return (
		<div className='Header h-[100px] min-h-[80px] px-5 md:px-20 lg:px-40 bg-primary shadow-lg max-h-[100px] z-50 w-full flex items-center justify-between fixed top-0'>
			<a href={"/"}>
				<img
					src={enLanguage ? "/en.webp" : "/ar.webp"}
					alt='logo'
					width={140}
					height={140}
					loading='lazy'
				/>
			</a>

			{/* Navbar */}
			<div
				className={`${
					isNavbarOpen ? "block" : "hidden"
				} md:flex md:items-center`}
			>
				<div
					className={`switch-lang flex justify-between items-center gap-1 md:gap-5 ${
						isNavbarOpen ? "flex-" : ""
					}`}
				>
					<div className='cursor-pointer text-sm md:text-lg w-16 sm-w-20 h-10 md:w-24 md:h-12 py-2 px-3 md:py-4 md:px-8 text-primary text-center flex justify-center items-center transition-all ease-in-out duration-300 rounded-lg bg-white hover:shadow-lg hover:bg-[#fce4ec]'>
						<a href='/products'>Products</a>
					</div>

					<button
						type='button'
						className={`cursor-pointer text-sm md:text-lg w-16 sm-w-20 h-10 md:w-24 md:h-12 py-2 px-3 md:py-4 md:px-8 text-primary text-center flex justify-center items-center transition-all ease-in-out duration-300 rounded-lg ${
							!enLanguage
								? "bg-[#fce4ec] border-2 border-white shadow-lg shadow-white hover:shadow-lg"
								: "bg-white hover:bg-[#fce4ec]"
						}`}
						onClick={() => handleLanguageToggle(false)}
						disabled={!enLanguage}
					>
						عربي
					</button>
					<button
						type='button'
						className={`cursor-pointer text-sm md:text-lg w-16 sm-w-20 h-10 md:w-24 md:h-12 py-2 px-3 md:py-4 md:px-8 text-primary text-center flex justify-center items-center transition-all ease-in-out duration-300 rounded-lg ${
							enLanguage
								? "bg-[#fce4ec] border-2 border-white shadow-lg shadow-white hover:shadow-lg"
								: "bg-white hover:bg-[#fce4ec]"
						}`}
						onClick={() => handleLanguageToggle(true)}
						disabled={enLanguage}
					>
						English
					</button>
					<Cart />
				</div>
			</div>
			{/* Toggle Button */}
			<button
				className='text-white md:hidden text-xl'
				onClick={() => setIsNavbarOpen(!isNavbarOpen)}
			>
				☰
			</button>
		</div>
	);
};

export default Header;
