// store.js
import { configureStore } from "@reduxjs/toolkit";
import langReducer from "./slices/langSlice"; // Adjust the path to where your langSlice is located

const store = configureStore({
	reducer: {
		lang: langReducer,
	},
});

export default store;
