import React from "react";

function ReturnsInEnglish() {
	return (
		<div className='px-5 md:px-20 lg:px-40 flex flex-col gap-5 mt-40'>
			<h2 className='text-3xl font-bold'>Returns $ Exchange Policy</h2>

			<div className='py-1'>
				At Shouldees, we want you to be completely satisfied with your purchase.
				If you're unhappy with your order, we offer a straightforward return
				process. Please be sure to read our return policy carefully before
				initiating a return.
			</div>
			<div className='py-1'>
				<h3 className='font-bold text-lg'>Return Conditions</h3>
				<span className='font-bold pr-2'>Eligibility:</span> The returned item
				must be unused, unwashed, and in the same condition as when you received
				it, including its original packaging. Time Frame: You have 30 days from
				the date of purchase to initiate a return. Non-Returnable Items: You
				cannot return items that are worn, washed, or not in the original
				packaging.
			</div>
			<div className='py-1'>
				<h3 className='font-bold text-lg'>Return Process</h3>
				<span className='font-bold pr-2'>Contact Us:</span> Before returning an
				item, please get in touch with our customer service team at{" "}
				<a href={`mailto:shouldees@shouldees.com?subject=Returns`}>
					shouldees@shouldees.com
				</a>{" "}
				with your order number and details about the product you wish to return.
			</div>
			<div className='py-1'>
				<h3 className='font-bold text-lg'>Return Shipping:</h3>
				Important Notes We do not cover the cost of return shipping. Items
				returned without contacting customer service or that do not meet the
				return criteria will not be accepted.
			</div>
			<div className='py-1'>
				<h3 className='font-bold text-lg'>Return Process</h3>
				Customers are responsible for the cost of return shipping. Please use a
				trackable shipping service or purchase shipping insurance. We cannot
				guarantee that we will receive your returned item. Refunds: Once your
				return is received and inspected, we will email you to notify you of the
				approval or rejection of your refund. If approved, your refund will be
				processed, and a credit will be automatically applied to your original
				payment method within a certain number of days.
			</div>
			<div className='py-1'>
				<h3 className='font-bold text-lg'>Need Help?</h3>
				If you have any questions about our return policy, please feel free to
				email us at{" "}
				<a href={`mailto:shouldees@shouldees.com?subject=Returns`}>
					shouldees@shouldees.com
				</a>
				.
			</div>
		</div>
	);
}

export default ReturnsInEnglish;
