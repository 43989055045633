import React, { useEffect, useState } from "react";

function VideoSection() {
	const [isMounted, setIsMounted] = useState(false);

	useEffect(() => {
		setIsMounted(true);
	}, []);

	if (!isMounted) {
		return null;
	}
	return (
		<div className='video-section mt-32 grid gap-4 sm:grid-cols-1 md:grid-cols-2 '>
			<div className='w-full'>
				<video
					controls
					autoPlay
					className='video w-full h-auto md:h-[550px] md:max-h-[550px] md:min-h-[550px] rounded-lg shadow-lg'
				>
					<source
						src='/vedios/one.mp4'
						type='video/mp4'
					/>
					<track
						kind='captions'
						src='path/to/your/captions.vtt'
						lang='en'
						label='English'
					/>
				</video>
			</div>

			<div className='w-full'>
				<video
					controls
					autoPlay
					className='video w-full h-auto md:h-[550px] md:max-h-[550px] md:min-h-[550px] rounded-lg shadow-lg'
				>
					<source
						src='/vedios/two.mp4'
						type='video/mp4'
					/>
					<track
						kind='captions'
						src='path/to/your/captions.vtt'
						lang='en'
						label='English'
					/>
				</video>
			</div>
		</div>
	);
}

export default VideoSection;
