import React from "react";
import Footer from "./Footer.tsx";
import Header from "./Header.tsx";
import EnglishSizes from "./EnglishSizes.tsx";
import ArabicSizes from "./ArabicSizes.tsx";
import { useDispatch, useSelector } from "react-redux";
import { toggleLanguage, setLanguage } from "../redux/slices/langSlice";
function MainCartPage() {
	const dispatch = useDispatch();

	const enLanguage = useSelector((state: any) => state?.lang?.enLanguage);

	console.log("Redux enLanguage:", enLanguage);
	const handleLanguageToggle = () => {
		// dispatch(toggleLanguage());
	};

	return (
		<>
			<Header
				enLanguage={enLanguage}
				setEnLanguage={handleLanguageToggle}
			/>
			<div className='min-h-screen flex flex-col justify-between px-5 md:px-20 lg:px-40  gap-5 '>
				{enLanguage ? <EnglishSizes /> : <ArabicSizes enLanguage={false} />}
			</div>
			<Footer enLanguage={enLanguage} />
		</>
	);
}

export default MainCartPage;
