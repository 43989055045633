import React from "react";

function PrivacyPolicyInEnglish() {
	return (
		<div className='px-5 md:px-20 lg:px-40 flex flex-col gap-5 mt-40'>
			<h2 className='text-3xl font-bold'>
				Privacy Policy for Shouldees
			</h2>
			<ul className='flex flex-col gap-5'>
				<li className=' font-semibold text-xl'>
					At Shouldees, your privacy is important to us. Here’s how we handle
					your information:
				</li>
				<li>
					<span className='font-bold pr-2'>Collection of Information:</span> We
					collect your name, email, and payment details when you make a purchase
					or subscribe to our newsletter.
				</li>
				<li>
					<span className='font-bold pr-2'>Usage of Information:</span> We use
					your data to process orders, communicate with you, and improve our
					services. With your consent, we may use it for marketing.
				</li>
				<li>
					<span className='font-bold pr-2'>Data Protection:</span> We secure
					your data from unauthorized access. Payments are processed through
					trusted gateways.
				</li>
				<li>
					<span className='font-bold pr-2'>Sharing of Information:</span> We
					don't sell or share your data except for necessary services (e.g.,
					shipping) or legal requirements.
				</li>
				<li>
					<span className='font-bold pr-2'>Cookies:</span> We use cookies to
					improve your experience and collect analytics. You can disable cookies
					in your browser settings.
				</li>
				<li>
					<span className='font-bold pr-2'>Your Rights:</span> You can access,
					update, or delete your personal information by contacting us.
				</li>
				<li>
					<span className='font-bold pr-2'>Contact Us:</span> For any questions,
					email us at{" "}
					<a
						href={`mailto:shouldees@shouldees.com?subject=Privacy Policy Inquiry`}
					>
						shouldees@shouldees.com
					</a>{" "}
					. <br />
					By using our services, you agree to this privacy policy.
				</li>
			</ul>
		</div>
	);
}

export default PrivacyPolicyInEnglish;
