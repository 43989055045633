import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { toggleLanguage, setLanguage } from "../redux/slices/langSlice";
import Header from "./Header.tsx";
import Footer from "./Footer.tsx";
import HoveredBtn from "./HoveredBtn.tsx";
import PrivacyPolicyInEnglish from "./PrivacyPolicyInEnglish.tsx";
import PrivacyPolicyInArabic from "./PrivacyPolicyInArabic.tsx";
function PrivacyPolicyPage() {
	const dispatch = useDispatch();

	const enLanguage = useSelector((state: any) => state?.lang?.enLanguage);

	console.log("Redux enLanguage:", enLanguage);
	const handleLanguageToggle = () => {
		// dispatch(toggleLanguage());
	};

	return (
		<>
			<Header
				enLanguage={enLanguage}
				setEnLanguage={handleLanguageToggle}
			/>
			{enLanguage ? <PrivacyPolicyInEnglish /> : <PrivacyPolicyInArabic />}
			<HoveredBtn enLanguage={enLanguage} />
			<Footer enLanguage={enLanguage} />
		</>
	);
}

export default PrivacyPolicyPage;
