import React from 'react'

function TermsArabic() {
  return (
    <>
			<div
				dir='rtl'
				className='px-5 md:px-20 lg:px-40 flex flex-col gap-5 mt-40'
			>
				<h2 className='text-3xl font-bold'>شروط الخدمة لشركة شولديز</h2>
				<ul className='flex flex-col gap-5'>
					<li>
						<span className='font-bold pr-2'> 1. قبول الشروط :</span> باستخدام
						موقع شولديز، فإنك توافق على هذه الشروط والأحكام. إذا كنت لا توافق،
						فلا تستخدم خدماتنا.
					</li>
					<li>
						<span className='font-bold pr-2'> 2. استخدام الموقع :</span> استخدام
						الموقع استخدم موقع شولديز لأغراض قانونية فقط. لا تنشر مواد ضارة أو
						مسيئة.
					</li>
					<li>
						<span className='font-bold pr-2'> 3. مسؤوليات الحساب:</span> قدم
						معلومات دقيقة عند إنشاء الحساب. أنت مسؤول عن حسابك وكلمة المرور
						الخاصة بك.
					</li>
					<li>
						<span className='font-bold pr-2'> 4. شراء المنتجات:</span> الطلبات
						تخضع للقبول. نحتفظ بالحق في رفض أو إلغاء أي طلب. قد تتغير الأسعار
						والتوافر دون إشعار.
					</li>
					<li>
						<span className='font-bold pr-2'> 5. الشحن والتسليم:</span> قد تختلف
						أوقات التسليم. نحن غير مسؤولين عن التأخيرات أو المشاكل الناتجة عن
						الشحن.
					</li>
					<li>
						<span className='font-bold pr-2'> 6. الإرجاع والاسترداد :</span>{" "}
						نقبل الإرجاع والاسترداد وفقًا لسياسة الإرجاع الخاصة بنا. يجب إعادة
						المنتجات في حالتها الأصلية.
					</li>
					<li>
						<span className='font-bold pr-2'> 7. الملكية الفكرية :</span>{" "}
						الملكية الفكرية المحتوى على موقع شولديز محمي بقوانين . لا تستخدم
						المحتوى دون إذن.
					</li>
					<li>
						<span className='font-bold pr-2'> 8. تحديد المسؤولية:</span> شولديز
						غير مسؤولة عن أي أضرار ناتجة عن استخدام موقعنا أو منتجاتنا.
					</li>
					<li>
						<span className='font-bold pr-2'> 9. التنازلات :</span>
						تم تصنيع وسادات الأكتاف المضادة للانزلاق من Shouldees من مواد آمنة
						وعالية الجودة دون أي مشكلات معروفة. ومع ذلك، فإن استخدام هذا المنتج
						يعتمد على تقدير المستخدم. لا تتحمل Shouldees أي مسؤولية عن أي ردود
						فعل غير متوقعة أو سوء استخدام. يُرجى التوقف عن الاستخدام في
						حال حدوث أي إزعاج.
					</li>
					<li>
						<span className='font-bold pr-2'> 10. التعويض :</span>توافق على
						تعويض شولديز عن أي مطالبات أو أضرار تتعلق باستخدامك لموقعنا أو
						انتهاكك لهذه الشروط.
					</li>
					<li>
						<span className='font-bold pr-2'> 11. تغييرات في الشروط :</span> قد
						نقوم بتحديث هذه الشروط. استخدامك المستمر للموقع يعني قبولك للشروط
						المحدثة.
					</li>
					<li>
						<span className='font-bold pr-2'> 12. القانون الحاكم: </span> تحكم
						هذه الشروط بقوانين [بلدك/ولايتك]. سيتم حل النزاعات في محاكم
						[بلدك/ولايتك].
					</li>
					<li>
						<span className='font-bold pr-2'>اتصل بنا</span> لأي استفسارات،
						راسلنا على
						<a href={`mailto:shouldees@shouldees.com?subject=Terms of Service`}>
							shouldees@shouldees.com
						</a>
						<br />
						باستخدامك لخدماتنا، فإنك توافق على هذه الشروط والأحكام.
					</li>
				</ul>
			</div>
		</>
  )
}

export default TermsArabic