import React from "react";

function FixedShopNow() {
	return (
		<a
			className='fixed bottom-5 right-5 p-5 rounded-full shadow-lg hover:shadow-primary shadow-white bg-primary text-white cursor-pointer hover:bottom-8 duration-300 ease-in-out hover:bg-white hover:border-primary border-white border-2 hover:text-primary'
			href={"/products"}
		>
			Shop Now
		</a>
	);
}

export default FixedShopNow;
