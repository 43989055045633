import React from "react";
import Card from "./Card.tsx";

type ArabicTextProps = {
	enLanguage: boolean;
};
const ArabicText: React.FC<ArabicTextProps> = ({ enLanguage }) => {
	return (
		<div className='flex flex-col md:flex-row-reverse gap-10 mt-20 justify-between items-center'>
			<h3
				className='text-primary  text-[22px] md:text-3xl mt-0 md:mt-10 leading-[1.5]'
				dir='rtl'
			>
				ارتقي بإطلالتك مع وسادات الأكتاف المضادة للانزلاق, شولديز, تعزز هذه
				الوسادات القابلة لإعادة الاستخدام شكل أكتافك للحصول على مظهر أنيق تجعل
				خطوط أكتافك بزاوية 90 درجة. يوفر الجزء المصنوع من السيليكون ثباتًا ويمنع
				حمالات الصدر من الحفر في الجلد، تضيف لمسة من الأناقة دون جهد إلى أي زي.
			</h3>
			<Card enLanguage={enLanguage} />
		</div>
	);
};

export default ArabicText;
