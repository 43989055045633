import logo from "./logo.svg";
import "./App.css";
import { useState } from "react";
import Home from "./pages/Home";
// import Products from "./pages/Products";
// import PrivacyPolicy from "./pages/PrivacyPolicy";
// import Terms from "./pages/Terms";
import { Routes, Route, Link, useNavigate } from "react-router-dom";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import Products from "./pages/Products";
import Terms from "./pages/Terms";
import Cart from "./pages/Cart";
import Returns from "./pages/Returns";

function App() {
	const navigate = useNavigate();
	const [state, setstate] = useState(false);

	return (
		<div>
			<Routes>
				<Route path='/' element={<Home state={state} />}></Route>
				<Route path='/products' element={<Products state={state} />}></Route>
				<Route
					path='/privacy-policy'
					element={<PrivacyPolicy state={state} />}
				></Route>
				<Route path='/terms' element={<Terms state={state} />}></Route>
				<Route path='/cart' element={<Cart state={state} />}></Route>
				<Route path='/returns' element={<Returns state={state} />}></Route>
			</Routes>
		</div>
	);
}

export default App;
