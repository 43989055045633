// slices/langSlice.js
"use client";

import { createSlice } from "@reduxjs/toolkit";

const initialState = {
	enLanguage: true,
};

export const langSlice = createSlice({
	name: "language",
	initialState,
	reducers: {
		toggleLanguage: (state) => {
			state.enLanguage = !state.enLanguage;
		},
		setLanguage: (state, action) => {
			state.enLanguage = action.payload;
		},
	},
});

export const { toggleLanguage, setLanguage } = langSlice.actions;

export default langSlice.reducer;
