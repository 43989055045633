import React from "react";
import { motion } from "framer-motion";

type HeroProps = {
	enLanguage: boolean;
};
const HeroSection: React.FC<HeroProps> = ({ enLanguage }) => {
	const arImages = [
		{
			id: 1,
			srcImg: "/images/01-1.webp",
		},
		{
			id: 2,
			srcImg: "/images/01-2.webp",
		},
		{
			id: 21,
			srcImg: "/images/enhance1a972875a.webp",
		},
		{
			id: 22,
			srcImg: "/images/enhance40a082f6a.webp",
		},
		{
			id: 23,
			srcImg: "/images/enhance46c534493.webp",
		},
		{
			id: 24,
			srcImg: "/images/enhance3343919ef.webp",
		},
		{
			id: 25,
			srcImg: "/images/enhance4189dd529.webp",
		},
		{
			id: 26,
			srcImg: "/images/enhance4f9561b11.webp",
		},
		{
			id: 27,
			srcImg: "/images/enhance32e18401e.webp",
		},
		{
			id: 28,
			srcImg: "/images/enhance13129b25b.webp",
		},

		{
			id: 3,
			srcImg: "/images/03-2.webp",
		},
		{
			id: 4,
			srcImg: "/images/03-3.webp",
		},

		{
			id: 7,
			srcImg: "/images/114079168-enhance44a913ee04X.webp",
		},
		{
			id: 8,
			srcImg: "/images/114092203-enhance2e52208484X.webp",
		},

		{
			id: 14,
			srcImg: "/images/6682.webp",
		},
		{
			id: 13,
			srcImg: "/images/6675.webp",
		},
		{
			id: 16,
			srcImg: "/images/enhance47bd60d70.webp",
		},
		{
			id: 15,
			srcImg: "/images/51.webp",
		},

		{
			id: 19,
			srcImg: "/images/IMG7128.webp",
		},
		{
			id: 20,
			srcImg: "/images/WechatIMG520.webp",
		},
	];
	const enImages = [
		{
			id: 2,
			srcImg: "/images/01-2.webp",
		},
		{
			id: 1,
			srcImg: "/images/01-1.webp",
		},
		{
			id: 22,
			srcImg: "/images/enhance40a082f6a.webp",
		},
		{
			id: 21,
			srcImg: "/images/enhance1a972875a.webp",
		},
		{
			id: 24,
			srcImg: "/images/enhance3343919ef.webp",
		},
		{
			id: 23,
			srcImg: "/images/enhance46c534493.webp",
		},
		{
			id: 26,
			srcImg: "/images/enhance4f9561b11.webp",
		},
		{
			id: 25,
			srcImg: "/images/enhance4189dd529.webp",
		},
		{
			id: 28,
			srcImg: "/images/enhance13129b25b.webp",
		},
		{
			id: 27,
			srcImg: "/images/enhance32e18401e.webp",
		},

		{
			id: 4,
			srcImg: "/images/03-3.webp",
		},
		{
			id: 3,
			srcImg: "/images/03-2.webp",
		},

		{
			id: 8,
			srcImg: "/images/114092203-enhance2e52208484X.webp",
		},
		{
			id: 7,
			srcImg: "/images/114079168-enhance44a913ee04X.webp",
		},

		{
			id: 13,
			srcImg: "/images/6675.webp",
		},
		{
			id: 14,
			srcImg: "/images/6682.webp",
		},
		{
			id: 15,
			srcImg: "/images/51.webp",
		},
		{
			id: 16,
			srcImg: "/images/enhance47bd60d70.webp",
		},

		{
			id: 20,
			srcImg: "/images/WechatIMG520.webp",
		},
		{
			id: 19,
			srcImg: "/images/IMG7128.webp",
		},
	];
	return (
		<div className='mt-[80px] flex items-center justify-center'>
			{enLanguage ? (
				<div className='grid grid-cols-2 gap-4'>
					{enImages.map((img) => (
						<div key={img.id}>
							<motion.div
								whileInView={{ y: [30, 0], x: [-10, 0], opacity: [0, 1] }}
								transition={{ duration: 1 }}
								className='flex flex-col justify-center items-center cursor-pointer transition-all ease-in-out duration-300 rounded-[40px] pb-5 hover:shadow-xl hover:bg-white/80 text-white hover:text-primary'
							>
								<div>
									<img
										className='h-[300px] md:h-[600px] shadow-2xl shadow-black/50 hover:object-center hover:scale-105 transition-all ease-in-out duration-300 items-center object-cover  max-w-full rounded-lg'
										src={img.srcImg}
										alt='image'
										width={500}
										height={500}
										loading='lazy'
									/>
								</div>
							</motion.div>
						</div>
					))}

					<div>
						<motion.div
							whileInView={{ y: [30, 0], x: [-10, 0], opacity: [0, 1] }}
							transition={{ duration: 1 }}
							className='flex flex-col justify-center items-center cursor-pointer transition-all ease-in-out duration-300 rounded-[40px] pb-5 hover:shadow-xl hover:bg-white/80 text-white hover:text-primary'
						>
							<div>
								<img
									className='h-[300px] shadow-lg md:h-[700px] hover:scale-105 transition-all ease-in-out duration-300 items-center object-top object-cover  max-w-full rounded-lg'
									src='/images/IMG7052.webp'
									alt='image'
									width={500}
									height={500}
									loading='lazy'
								/>
							</div>
						</motion.div>
					</div>
					<div>
						<motion.div
							whileInView={{ y: [30, 0], x: [-10, 0], opacity: [0, 1] }}
							transition={{ duration: 1 }}
							className='flex flex-col justify-center items-center cursor-pointer transition-all ease-in-out duration-300 rounded-[40px] pb-5 hover:shadow-xl hover:bg-white/80 text-white hover:text-primary'
						>
							{" "}
							<div>
								<img
									className='h-[300px] shadow-lg hover:scale-105 transition-all ease-in-out duration-300 md:h-[700px] items-center object-cover  max-w-full rounded-lg'
									src='/images/IMG7051.webp'
									alt='image'
									width={500}
									height={500}
									loading='lazy'
								/>
							</div>
						</motion.div>
					</div>
				</div>
			) : (
				<div className='grid grid-cols-2 gap-4'>
					{arImages.map((img) => (
						<div key={img.id}>
							<motion.div
								whileInView={{ y: [30, 0], x: [-10, 0], opacity: [0, 1] }}
								transition={{ duration: 1 }}
								className='flex flex-col justify-center items-center cursor-pointer transition-all ease-in-out duration-300 rounded-[40px] pb-5 hover:shadow-xl hover:bg-white/80 text-white hover:text-primary'
							>
								<div>
									<img
										className='h-[300px] md:h-[600px] shadow-2xl shadow-black/50 hover:object-center hover:scale-105 transition-all ease-in-out duration-300 items-center object-cover  max-w-full rounded-lg'
										src={img.srcImg}
										alt='image'
										width={500}
										height={500}
										loading='lazy'
									/>
								</div>
							</motion.div>
						</div>
					))}
					<div>
						<motion.div
							whileInView={{ y: [30, 0], x: [-10, 0], opacity: [0, 1] }}
							transition={{ duration: 1 }}
							className='flex flex-col justify-center items-center cursor-pointer transition-all ease-in-out duration-300 rounded-[40px] pb-5 hover:shadow-xl hover:bg-white/80 text-white hover:text-primary'
						>
							{" "}
							<div>
								<img
									className='h-[300px] shadow-lg hover:scale-105 transition-all ease-in-out duration-300 md:h-[700px] items-center object-cover  max-w-full rounded-lg'
									src='/images/IMG7051.webp'
									alt='image'
									width={500}
									height={500}
									loading='lazy'
								/>
							</div>
						</motion.div>
					</div>
					<div>
						<motion.div
							whileInView={{ y: [30, 0], x: [-10, 0], opacity: [0, 1] }}
							transition={{ duration: 1 }}
							className='flex flex-col justify-center items-center cursor-pointer transition-all ease-in-out duration-300 rounded-[40px] pb-5 hover:shadow-xl hover:bg-white/80 text-white hover:text-primary'
						>
							{" "}
							<div>
								<img
									className='h-[300px] shadow-lg md:h-[700px] hover:scale-105 transition-all ease-in-out duration-300 items-center object-top object-cover  max-w-full rounded-lg'
									src='/images/IMG7052.webp'
									alt='image'
									width={500}
									height={500}
									loading='lazy'
								/>
							</div>
						</motion.div>
					</div>
				</div>
			)}
		</div>
	);
};

export default HeroSection;
